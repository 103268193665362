<template>
  <div class="monitors">
    <status-page-monitors mode="update" :status-page="statusPage" @refreshStatusPage="emitRefreshStatusPage"/>
  </div>
</template>

<script>
import StatusPageMonitors from '@/components/Forms/StatusPageForm/StatusPageMonitors.vue'

export default {
  components: {
    StatusPageMonitors
  },

  metaInfo: {
    title: 'Monitors'
  },

  props: {
    statusPage: {
      required: true,
      type: Object
    }
  },

  methods: {
    emitRefreshStatusPage () {
      this.$emit('refreshStatusPage')
    }
  }
}
</script>
