<template>
  <div>
    <div class="row">
      <div class="col-6">
        <h3>
          Layout
        </h3>
        <div class="card" style="position: sticky; top: 80px;">
          <div class="card-body card-group-list" style="overflow-y: scroll; height: 100vh;">
            <base-alert v-if="groupList.length === 0" class="mb-2">Drag and drop a monitor from here -></base-alert>
            <draggable v-model="groupList" :preventOnFilter="false" filter=".exclude" class="card-group-list selected-monitors-list" group="monitors" :move="handleEvent">
              <div v-for="item in groupList" :key="item.id || item.groupId">
                <div v-if="item.monitors" class="card card-group">
                  <div class="card-body">
                    <IconCloseCircle width="22" height="22" color="#6551E0" class="card-group-delete-button" @click="deleteGroup(item.groupId)"/>
                        <base-textbox
                          v-model="item.title"
                          label="Group TITLE"
                          placeholder="Title of the status page"
                          class="exclude"
                          ref="title"
                        />
                    <h5 class="uppercase card-group-monitors-title">
                      Monitors
                    </h5>
                    <p v-if="item.monitors.length === 0" class="card-group-placeholder">
                      Drag and drop a monitor from here ->
                    </p>
                    <draggable class="list-group list-group-monitors" style="min-height: 40px;" v-model="item.monitors" group="monitors" :move="handleEvent">
                      <MonitorCard
                        v-for="element in item.monitors"
                        :key="element.title"
                        :name="element.name"
                        :url="element.url"
                        :status="element.status"
                      />
                    </draggable>
                  </div>
                </div>
                <MonitorCard v-else :name="item.name" :url="item.url" :status="item.status"/>
              </div>
            </draggable>
            <button type="button" class="button-add-group" @click="pushGroup">+ Add New Group</button>
          </div>
        </div>
      </div>
      <div class="col-6">
        <h3>
          Monitors
        </h3>
        <div class="card">
          <div class="card-body" style="overflow-y: scroll; height: 100vh;">
            <base-textbox-search v-model="searchValue"  @input="search" class="mb-3" placeholder="Search..."/>
            <monitor-list-loading v-if="loading" :number-of-placeholders="numberOfPlaceholders"/>

            <base-alert v-if="monitors.length === 0 && !loading" class="mb-2">No monitors</base-alert>
            <draggable class="list-group list-group-monitors"
                       v-model="monitors"
                       group="monitors"
                       :move="moveToGroup"
                       v-show="!loading">
              <MonitorCard
                v-for="(element) in monitors"
                :key="element.id"
                :name="element.name"
                :url="element.url"
                :status="element.status"
              />
            </draggable>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4 monitors-actions">
      <div class="col">
        <div class="d-flex toolbar">
          <base-button
            class="ml-0"
            color="success"
            ref="submit"
            :loading="saving"
            @click="saveChanges"
          >
            Save Changes
          </base-button>
          <base-button
            class="ml-2"
            color="light"
            @click="preview"
          >
            Preview
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MonitorCard from '@/components/StatusPage/Monitor/MonitorCard.vue'

import monitors from '@/mixins/monitors'

import statusPagesApi from '@/api/statusPagesApi'

import tippy from 'tippy.js'
import draggable from 'vuedraggable'
import monitorsApi from '@/api/monitorsApi'
import IconCloseCircle from '@/components/Icons/IconCloseCircle.vue'
import BaseTextboxSearch from '@/components/Base/BaseTextboxSearch.vue'
import MonitorListLoading from '@/components/MonitorList/Desktop/MonitorListLoading.vue'

export default {
  name: 'StatusPageMonitors',

  components: {
    MonitorListLoading,
    BaseTextboxSearch,
    IconCloseCircle,
    MonitorCard,
    draggable
  },

  mixins: [ monitors ],

  props: {
    statusPage: {
      required: false,
      type: Object
    }
  },

  data () {
    return {
      groupList: [],
      group: [],
      monitors: [],
      saving: false,
      numberOfPlaceholders: 10,
      searchValue: '',
      loading: true
    }
  },

  async created () {
    this.selectedMonitors = this.statusPage.monitors.slice()
    await this.loadMonitors('', this.selectedMonitors.length + 30)
    await this.getGroupList()
    this.groupList = this.mapGroupList()
    this.monitors = this.notSelectedMonitors.map(this.mapItemForCard)
    this.loading = false
  },

  mounted () {
    localStorage.removeItem('status_page_monitors_preview')

    this.updateButtonTippy = tippy(this.$refs.submit.$el, {
      content: 'Status page was successfully updated',
      arrow: false,
      placement: 'right',
      trigger: 'manual'
    })
  },

  methods: {
    async moveToGroup (event) {
      const draggedContext = event.draggedContext.element
      const toTargetClassList = [...event.to.classList]
      if (toTargetClassList.find(className => className === 'selected-monitors-list')) {
        this.select(draggedContext)
        await this.loadMonitors(this.searchValue, this.selectedMonitors.length + 10)
      }
    },

    async search () {
      this.loading = true
      await this.loadMonitors(this.searchValue, this.selectedMonitors.length + 10)
      this.monitors = this.notSelectedMonitors.map(this.mapItemForCard)
      this.loading = false
    },

    async saveGroups (groups) {
      await monitorsApi.saveMonitorGroups(this.statusPage.id, groups)
    },
    async getGroupList () {
      try {
        const groups = await monitorsApi.getMonitorGroups(this.statusPage.id)

        this.group = groups.map(item => ({
          id: String(item.id),
          title: item.name,
          monitors: item.status_page_group_monitors.map(monitor => String(monitor.monitor_id))
        }))
      } catch (e) {
        this.group = []
      }
    },
    mapGroupList () {
      return this.selectedMonitors.map(this.mapItemForCard).reduce((acc, item) => {
        const findGroup = this.group.find(groupItem => {
          return groupItem.monitors.find(id => id === item.id)
        })

        if (findGroup) {
          const groupIndex = acc.findIndex(elem => elem.groupId === findGroup.id)

          if (groupIndex !== -1) {
            acc[groupIndex].monitors.push(item)
          } else {
            acc.push({
              groupId: findGroup.id,
              title: findGroup.title,
              monitors: [item]
            })
          }
        } else {
          acc.push(item)
        }

        return acc
      }, [])
    },
    mapGroupResult () {
      return this.groupList.filter(item => item.monitors).map(item => ({
        id: this.group.find(group => group.id === item.groupId) ? item.groupId : null,
        title: item.title,
        monitors: item.monitors.map(monitor => monitor.id)
      }))
    },
    pushGroup () {
      this.groupList.push({
        groupId: Date.now(),
        title: 'Group title',
        monitors: []
      })
    },
    deleteGroup (id) {
      const deleteGroupIndex = this.groupList.findIndex(group => group.groupId === id)

      if (deleteGroupIndex !== -1) {
        this.groupList[deleteGroupIndex].monitors.forEach(monitor => {
          this.remove(monitor)
        })
        this.monitors.push(...this.groupList[deleteGroupIndex].monitors)
      }

      this.groupList.splice(deleteGroupIndex, 1)
    },
    showUpdateSuccess () {
      this.updateButtonTippy.show()

      setTimeout(() => {
        this.updateButtonTippy.hide()
      }, 2000)
    },
    async saveChanges () {
      this.saving = true

      await this.updateStatusPage()

      const saveGroups = this.mapGroupResult()
      await this.saveGroups(saveGroups)

      this.saving = false

      this.showUpdateSuccess()

      this.$emit('refreshStatusPage')
    },
    async updateStatusPage () {
      const selectedMonitors = this.groupList.reduce((acc, item) => {
        if (item.monitors) {
          acc.push(...item.monitors.map(monitor => monitor.id))
        } else {
          acc.push(item.id)
        }

        return acc
      }, [])
      console.log(selectedMonitors)
      await statusPagesApi.update(
        this.statusPage.id,
        {
          ...this.statusPage,
          monitors: selectedMonitors
        }
      )
    },
    async preview () {
      await this.updateStatusPage()

      const payload = {
        statusPageId: this.$route.params.id,
        slug: this.statusPage.slug,
        groups: this.mapGroupResult()
      }
      window.localStorage.setItem('status_page_monitors_preview', JSON.stringify(payload))
      let routeData = this.$router.resolve({ name: 'status.overview', params: { slug: this.statusPage.slug }, query: { preview: true } })
      window.open(routeData.href, '_blank')
    },
    handleEvent (event) {
      const draggedContext = event.draggedContext.element
      const toTargetClassList = [...event.to.classList]

      if (draggedContext.monitors && toTargetClassList.find(className => className === 'list-group-monitors')) {
        return false
      }

      if (!draggedContext.monitors && toTargetClassList.find(className => className === 'list-group-monitors')) {
        this.remove(draggedContext)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.toolbar {
  z-index: 11;
  position: fixed;
  width: 100%;
  bottom:0;
  background: rgba(245, 248, 249, 0.9);
  padding: 15px 0;
  backdrop-filter: blur(3px);
}
.monitors-actions {
  padding-bottom: 30px;
}
.card-group-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.selected-monitors-list {
  padding-top: 110px;
  margin-top: -100px;
}

.list-group-monitors {
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  user-select: none;
}

.card-group {
  box-shadow: 0 2px 4px 0 #19221D0D;

  &-delete-button {
    position: absolute;
    top: -6px;
    right: -6px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    border: none;
    cursor: pointer;
  }

  &-delete-icon {
    flex-shrink: 0;
    height: 16px;
    width: 16px;
  }

  &-placeholder {
    position: absolute;
    font-size: 14px;
    line-height: 125%;
    font-weight: 500;
    color: rgba(27, 24, 35, 0.3);
  }

  &-monitors-title {
    opacity: .8;
    font-weight: 600;
    font-size: 11px;
    line-height: 12px;
    letter-spacing: 0.05em;
  }

  & > .card-body {
    border-radius: 8px;
    border: 1px solid #E0E0E0;
    background: #F6F6F6;
  }

  &::v-deep .card-monitor .card-body{
    background: #FFFFFF;
  }
}

::v-deep .card-monitor {
  &.sortable-ghost > .card-body {
    background: rgba(68, 143, 255, 0.1);
    border-color: #70A9FF;
  }

  &.sortable-chosen {
    cursor: pointer !important;
  }
}

.button-add-group {
  display: block;
  padding: 12px 16px;
  border-radius: 8px;
  text-align: center;
  border: none;
  width: 100%;
  font-size: 13px;
  font-weight: 500;
  line-height: 125%;
  color: #333333;
  background: #F6F6F6;
  transition: 0.1s background;

  &:hover {
    cursor: pointer;

    background: #E7E7E7;
  }
}
</style>
